'use client'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Cell, Divider, Dialog, Navigation, Spacer } from '@vinted/web-ui'

import useAbTest from 'hooks/useAbTest'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking/useTracking'

import InfoBanner from 'components/InfoBanner'
import ScrollableArea from 'components/ScrollableArea'
import { ExtraServicePolicyNote, ExtraServiceActivePayInMethod } from 'components/ExtraService'

import { Screen } from 'constants/tracking/screens'
import { InfoBannerScreen } from 'constants/info-banner'
import { ExtraServiceOrderStatus } from 'constants/extra-service'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import {
  getPaymentStatus,
  getWalletBalance,
  getActivePayInMethod,
  getSelectedCreditCard,
} from 'state/extra-service/selectors'
import { State as AppState } from 'state/types'
import { FeaturedCollectionOrderModel } from 'types/models'

import { clickEvent } from 'libs/common/event-tracker/events'
import { getPayButtonTitle } from 'libs/utils/checkout'

import OrderSummary from './OrderSummary'

type Props = {
  order?: FeaturedCollectionOrderModel
  show: boolean
  isMultipleCollectionsEnabled: boolean
  onBack: () => void
  onConfirmOrder: () => void
  onShowSalesTaxModal: () => void
  onPaymentMethodSelect: () => void
}

const OrderConfirmationModal = ({
  order,
  show,
  isMultipleCollectionsEnabled,
  onBack,
  onConfirmOrder,
  onShowSalesTaxModal,
  onPaymentMethodSelect,
}: Props) => {
  const { track } = useTracking()

  const translate = useTranslate('featured_collection.order_confirmation_modal')
  const translatePayInMethodError = useTranslate('payment_method_selection.errors')
  const translatePayButton = useTranslate('checkout.purchase')

  const [payInMethodError, setPayInMethodError] = useState<string>()

  const walletBalance = useSelector(getWalletBalance)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const orderStatus = useSelector(getPaymentStatus)
  const activePayInMethod = useSelector((state: AppState) =>
    getActivePayInMethod(state, order?.payable.amount),
  )

  const payButtonUnificationAbTest = useAbTest({
    abTestName: 'pay_button_unification',
    shouldTrackExpose: true,
  })

  const isOrderFree = !!order && parseFloat(order.payable.amount) <= 0
  const isOrderValid = !!activePayInMethod || isOrderFree
  const isConfirmationDisabled = !order || order.isTaxCoverageAvailable === false

  useEffect(() => {
    if (isOrderValid && payInMethodError) {
      setPayInMethodError(undefined)
    }
  }, [isOrderValid, payInMethodError])

  function handleShowSalesTaxModal() {
    onShowSalesTaxModal()

    track(
      clickEvent({
        target: ClickableElement.SalesTaxInfo,
        screen: Screen.FeaturedCollectionOrderConfirm,
        targetDetails: JSON.stringify({
          vas_order_id: order?.id,
        }),
      }),
    )
  }

  function renderNavigation() {
    return (
      <>
        <Cell>
          <Navigation
            body={translate('title')}
            testId="featured-collection-confirmation-modal-title"
          />
        </Cell>
        <div className="u-padding-horizontal-medium">
          <InfoBanner screen={InfoBannerScreen.VASCheckout} />
        </div>
        <Divider />
      </>
    )
  }

  function renderOrderSummary() {
    if (!order) return null

    return (
      <OrderSummary
        order={order}
        isMultipleCollectionsEnabled={isMultipleCollectionsEnabled}
        onShowSalesTaxModal={handleShowSalesTaxModal}
      />
    )
  }

  function handleConfirmOrder(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    if (orderStatus === ExtraServiceOrderStatus.Processing) {
      event.preventDefault()

      return
    }

    if (!isOrderValid) {
      setPayInMethodError(translatePayInMethodError('not_selected_payment_method'))

      return
    }

    track(
      clickEvent({
        screen: Screen.FeaturedCollectionOrderConfirm,
        target: ClickableElement.PayFeaturedCollection,
      }),
    )

    onConfirmOrder()
  }

  function renderBody() {
    if (!order) return null

    return (
      <>
        {renderOrderSummary()}
        <Divider />
        {!isOrderFree && (
          <>
            <ExtraServiceActivePayInMethod
              error={payInMethodError}
              walletBalance={walletBalance}
              selectedCreditCard={selectedCreditCard}
              activePayInMethod={activePayInMethod}
              onPaymentMethodSelect={onPaymentMethodSelect}
            />
            <Divider />
          </>
        )}
        <ExtraServicePolicyNote />
        <Spacer size={Spacer.Size.Large} />
      </>
    )
  }

  function renderActions() {
    const buttonTitle = getPayButtonTitle(payButtonUnificationAbTest)

    return (
      <Cell>
        <Button
          disabled={isConfirmationDisabled}
          isLoading={orderStatus === ExtraServiceOrderStatus.Processing}
          text={buttonTitle ? translatePayButton(buttonTitle) : translate('actions.place_order')}
          styling={Button.Styling.Filled}
          onClick={handleConfirmOrder}
          testId="featured-collection-order-confirmation--confirm-button"
        />
        <Spacer />
        <Button
          styling={Button.Styling.Flat}
          text={translate('actions.back')}
          testId="featured-collection-checkout-back-button"
          onClick={onBack}
        />
      </Cell>
    )
  }

  return (
    <Dialog show={show} hasScrollableContent>
      <div className="u-fill-width">
        {renderNavigation()}
        <ScrollableArea>
          {renderBody()}
          {renderActions()}
        </ScrollableArea>
      </div>
    </Dialog>
  )
}

export default OrderConfirmationModal
